import { createRouter, createWebHistory } from 'vue-router'
//import Home from '../views/Home.vue'
import Landing from '../views/Landing.vue'
import AuthReq from './auth-req'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Landing,
    meta: {
      hideNavBar: false
    }
  },
  {
    path: '/:categoryId/product/:productId?',
    name: 'Product',
    component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
    meta: {
      authReq: AuthReq.ADMIN
    }
  },
  {
    path: '/:categoryId/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue'),
    meta: {
      authReq: AuthReq.ADMIN
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "about" */ '../views/Support.vue'),
    meta: {
      authReq: AuthReq.NONE
    }
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Buy.vue'),
    meta: {
      authReq: AuthReq.ADMIN
    }
  },
  {
    name: "Login",
    path: "/login",
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      authReq: AuthReq.NONE
    }
  },
  {
    name: "Admin",
    path: "/admin",
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    meta: {
      authReq: AuthReq.ADMIN
    }
  },
  {
    name: 'Section',
    path: '/:categoryId/section/:sectionId?',
    component: () => import(/* webpackChunkName: "about" */ '../views/Section.vue'),
    meta: {
      authReq: AuthReq.ADMIN
    }
  },
  {
    name: 'Missing',
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "about" */ '../views/Missing.vue'),
    meta: {
      authReq: AuthReq.NONE
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // trying to access a restricted page + not logged in
  // redirect to login page
  if(to.meta == undefined || !to.meta.authReq || to.meta.authReq == AuthReq.NONE) {
    next();
    return;
  }

  let authReq = to.meta.authReq;
  let user = store.state.auth.user;
  if(!user) {
    next('/404');
    return;
  }

  let userHasAccess = user.roles.includes(authReq);
  if (!userHasAccess) {
    next('/login');
    return;
  }

  next();
});

export default router
