<template>
  <MqResponsive :target="['xs', 'sm']">
    <div id="NavLine">
      <router-link to="/" class="no-active">
        <div id="Logo">
          <ShiftZone></ShiftZone>
        </div>
      </router-link>
      <div id="Burger-Container" @click="showMobileNav">
        <span>{{ currentRoute }}</span>
        <Burger></Burger>
      </div>
      <div
        id="MobileDrop"
        v-bind:class="[shouldShowMobileNav ? 'show' : '', '']"
      >
        <div id="CloseBtnContainer">
          <div id="CloseBtn" @click="hideMobileNav">
            <Burger :isActive="true"></Burger>
          </div>
        </div>
        <NavBarContent @clicked="hideMobileNav" />
      </div>
    </div>
    <div
      id="ScreenCover"
      v-bind:class="[shouldShowMobileNav ? 'show' : '', '']"
      @click="hideMobileNav"
    ></div>
  </MqResponsive>

  <MqResponsive target="md+">
    <div id="NavLine">
      <router-link to="/" class="no-active">
        <div id="Logo">
          <ShiftZone></ShiftZone>
        </div>
      </router-link>

      <div id="Banner">
        <NavBarContent />
      </div>
    </div>
  </MqResponsive>
</template>

<script>
// @ is an alias to /src

import ShiftZone from "@szc/app/ShiftZone.vue";
import Burger from "./Burger.vue";
import NavBarContent from "./NavBarContent.vue";
import { MqResponsive } from "vue3-mq";

export default {
  name: "NavBar",
  props: {
    centerText: String,
  },
  components: {
    ShiftZone,
    Burger,
    NavBarContent,
    MqResponsive
  },
  data: () => {
    return {
      isMobileNavOpen: false,
    };
  },
  methods: {
    showMobileNav() {
      this.isMobileNavOpen = true;
    },
    hideMobileNav() {
      this.isMobileNavOpen = false;
    },
  },
  computed: {
    shouldShowMobileNav() {
      return this.isMobileNavOpen;
    },
    currentRoute() {
      return this.$route.name ? this.$route.name.toUpperCase() : "";
    }
  },
};
</script>

<style scoped>
#Logo {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px 0px;
}

#NavLine {
  display: flex;
  justify-content: space-between;
  z-index: 4;
}

#Burger-Container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "Open-Sans-Condensed";
  font-weight: bold;
}

#MobileDrop {
  position: fixed;
  right: -50vw;
  background-color: var(--grey);
  height: 100%;
  overflow: hidden;
  z-index: 9999;
  width: 35vw;
  transition: all 0.15s ease-in-out;
}

#MobileDrop.show {
  right: 0;
}

#MobileDrop #CloseBtnContainer {
  display: flex;
  flex-direction: row-reverse;
}

#MobileDrop #CloseBtn {
  padding: 5px;
  font-size: 50px;
  text-align: right;
  cursor: pointer;
  width: max-content;
}

#ScreenCover {
  display: none;
}
#ScreenCover.show {
  display: block;
  position: fixed;
  background: var(--grey);
  opacity: 0.2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  #Logo {
    width: 60px;
  }
}
</style>
