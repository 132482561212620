<template>
  <div class="nav-btns">
    <!-- <div id="Products" class="nav-link">
      <NavDropDown :routes="['/race/products', '/fight/products']">
        <template v-slot:btn-name>
          <div
            v-bind:class="['nav-link nav-btn']"
          >
            products
          </div>
        </template>
        <template v-slot:content>
          <div class="dropdown-nav-links">
            <router-link
              to="/race/products"
              class="nav-link"
              @click="onClickNav"
            >
              <div class="nav-btn race-nav">race</div>
            </router-link>
            <router-link
              to="/fight/products"
              class="nav-link"
              @click="onClickNav"
            >
              <div class="nav-btn fight-nav">fight</div>
            </router-link>
          </div>
        </template>
      </NavDropDown>
    </div>

    <div id="Shop" class="nav-link">
      <NavDropDown :routes="['/missing', '/admin']">
        <template v-slot:btn-name>
          <div
            v-bind:class="['nav-link nav-btn']"
          >
            Shop
          </div>
        </template>
        <template v-slot:content>
          <div class="dropdown-nav-links">
            <router-link
              to="/missing"
              class="nav-link"
              @click="onClickNav"
            >
              <div class="nav-btn race-nav">missing</div>
            </router-link>
            <router-link
              to="/admin"
              class="nav-link"
              @click="onClickNav"
            >
              <div class="nav-btn fight-nav">admin</div>
            </router-link>
          </div>
        </template>
      </NavDropDown>
    </div>

    <router-link to="/buy" class="nav-link" @click="onClickNav">
      <div class="nav-btn">Gallery</div>
    </router-link> -->
    <router-link to="/" class="nav-link" @click="onClickNav">
      <div class="nav-btn">Home</div>
    </router-link>
    <router-link to="/support" class="nav-link" @click="onClickNav">
      <div class="nav-btn">Support</div>
    </router-link>
    <div v-if="loggedIn" class="nav-link" @click="logout">
      <div class="nav-btn">Logout</div>
    </div>
  </div>
</template>

<script>
// import NavDropDown from "@szc/app/NavDropDown.vue";

export default {
  name: "NavBarContent",
  components: {
    // NavDropDown,
  },
  inject: ["mq"],
  computed: {
    isInProducts() {
      var routeName = this.$route.name;
      return "Products" == routeName;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    onClickNav() {
      this.$emit("clicked", "nav");
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
  },
};
</script>

<style>
#Banner {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Banner .text {
  font-size: 1.5rem;
}

.nav-btns {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

#NavLine .no-active {
  border-bottom: none;
}

#nav a {
  color: var(--white);
  text-decoration: none;
  font-size: 24px;
}

#nav .nav-link {
  font-size: 18px;
  font-family: "Baron";
  height: 100%;
  box-sizing: border-box;
  background: var(--grey);
  position: relative;
  z-index: 100;
}

#nav .nav-link:hover {
  background: black;
}

.nav-btn {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0px 2vw;
}

.race-nav {
  background-image: url("~@/assets/img/race-cat.jpg");
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 0.1em var(--grey);
}

.fight-nav {
  background-image: url("~@/assets/img/fight-cat.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: 0 0 0.1em var(--grey);
}

.dropdown-nav-links {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-content: center;
}

.dropdown-nav-links .nav-link {
  flex-grow: 1;
}

#nav a.router-link-exact-active:not(.no-active) {
  color: var(--mint-green);
  border-bottom: 5px solid var(--mint-green);
}

.highlight {
  background: black;
  color: var(--mint-green);
  border-bottom: 5px solid var(--mint-green);
}

/* For mobile styling, not sure why not working inside NavBar.vue */
#MobileDrop .nav-btns {
  flex-direction: column;
}

#MobileDrop .nav-link {
  height: 8vh;
  width: 100%;
}

#MobileDrop .nav-link {
  height: 8vh;
  width: 100%;
}

#MobileDrop #Products,
#MobileDrop #Shop {
  height: auto;
}

#MobileDrop .dropdown {
  height: auto;
  overflow: hidden;
}

#MobileDrop .dropdown-content {
  position: absolute;
  visibility: hidden;
  max-height: 0;
}

#MobileDrop .dropdown-content.show {
  display: inline-block;
  visibility: visible;
  top: 0px;
  position: relative;
}
</style>