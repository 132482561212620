<template>
  <div class="btn" :class="compClass">
    <a class="content"> <slot></slot></a>
  </div>
</template>

<script>
export default {
  name: "SZBtn",
  props: {
    label: {
      type: String
    }
  },
  inject: {
    dark: {
      default: false
    }
  },
  computed: {
    compClass() {
      return this.dark ? "dark" : "";
    }
  }
};
</script>

<style scoped>
.btn {
  display: inline-flex;
  color: var(--grey);
  justify-content: center; 
}

.btn .content {
  padding: 10px;
}

.btn.dark {
  color: var(--white)
}

.btn.dark a {
  border: 1px solid var(--white);
}

.btn.dark a:hover {
  border-bottom: 1px solid var(--mint-green);
}

a {
  text-decoration: none;
  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid var(--grey);
}

a:hover {
  border-bottom: 1px solid var(--mint-green);
}
</style>