<template>
    <div id="nav" v-if="!$route.meta.hideNavBar" :class="navClass">
      <NavBar :centerText="currentRoute">
      </NavBar>
    </div>

  <div class="main-content">
    <router-view v-slot="{ Component }">
      <!-- Use any custom transition and fallback to `fade` -->
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>

  <Footer></Footer>
</template>

<script>
import NavBar from "@szc/app/NavBar.vue";
import Footer from "@szc/app/Footer.vue";
import { MqResponsive } from "vue3-mq";

export default {
  el: "app",
  components: {
    NavBar,
    Footer,
    MqResponsive
  },
  inject: ["mq"],
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    navClass() {
      var reactiveClass = "";

      if (this.mq === "xs" || this.mq === "sm") {
        reactiveClass = "mob";
      }

      return reactiveClass;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--white);
}
#nav {
  padding: 0px 10vw;
  background: var(--grey);
}

@media only screen and (max-width: 600px) {
  #nav {
    padding: 0px 3vw;
  }
}


#nav a {
  color: var(--white);
  text-decoration: none;
  font-size: 24px;
}

.highlight {
  background: black;
  color: var(--mint-green);
  border-bottom: 5px solid var(--mint-green);
}


.route-enter-from {
  opacity: 0;
  transform: translateX(-100px);
}

.route-enter-active {
  transition: all 0.1s cubic-bezier(0.16, 1, 0.3, 1);
}

.route-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.route-leave-active {
  transition: all 0.1s cubic-bezier(0.16, 1, 0.3, 1);
}
</style>
