import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/main.css'
import { Vue3Mq } from 'vue3-mq'
import { createHead } from '@vueuse/head'
import { Head } from "@vueuse/head"

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

const app = createApp(App).use(store).use(router).use(createHead());

app.use(Vue3Mq, {
  preset: 'bootstrap5'
});

app.component('Head', Head);

app
.directive("click-outside", clickOutside)
.mount('#app');