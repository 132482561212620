<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 470.000000 175.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Shift Zone Logo - 
Created by potrace 1.16, written by Peter Selinger 2001-2019
</metadata>
<g transform="translate(0.000000,175.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M542 1739 c-232 -30 -337 -131 -388 -373 -8 -39 -17 -103 -21 -143
l-6 -73 209 0 209 0 0 88 c1 108 24 165 68 170 53 6 60 -11 53 -120 -3 -53 -9
-103 -12 -112 -5 -14 16 -16 204 -16 l209 0 7 53 c15 126 1 254 -38 340 -69
155 -240 219 -494 186z"/>
<path d="M1225 1698 c-4 -23 -75 -528 -75 -541 0 -4 85 -7 188 -7 l189 0 37
268 c21 147 40 275 42 285 5 16 -9 17 -185 17 -190 0 -191 0 -196 -22z"/>
<path d="M1807 1453 c-21 -148 -37 -276 -37 -285 0 -17 16 -18 188 -18 l187 0
38 273 c21 149 37 278 37 285 0 9 -44 12 -188 12 l-188 0 -37 -267z"/>
<path d="M2305 1698 c-4 -23 -75 -528 -75 -541 0 -4 86 -7 190 -7 130 0 190 3
190 11 0 16 69 510 75 537 l5 22 -190 0 c-189 0 -190 0 -195 -22z"/>
<path d="M2765 1698 c-4 -23 -75 -528 -75 -541 0 -4 85 -7 188 -7 l189 0 12
88 c6 48 14 102 17 120 l6 32 199 0 c198 0 199 0 204 23 2 12 12 74 20 137 9
63 18 127 21 143 l6 27 -391 0 -391 0 -5 -22z"/>
<path d="M3625 1698 c-2 -13 -12 -75 -20 -138 -9 -63 -18 -127 -21 -142 l-6
-28 166 0 c95 0 166 -4 166 -9 0 -5 -40 -297 -90 -649 -49 -351 -90 -647 -90
-656 0 -14 20 -16 188 -16 l188 0 83 598 c46 328 88 627 94 664 l9 68 166 2
167 3 17 120 c9 66 19 139 23 163 l6 42 -520 0 -521 0 -5 -22z"/>
<path d="M4307 1227 c-22 -159 -21 -167 1 -167 16 0 21 15 33 112 l14 113 97
-126 c89 -116 122 -148 136 -134 5 5 41 240 42 273 0 6 -8 12 -19 12 -20 0
-25 -15 -37 -120 -3 -36 -10 -71 -14 -79 -5 -9 -36 25 -101 110 -60 79 -102
125 -115 127 -19 3 -22 -5 -37 -121z"/>
<path d="M4329 997 c-122 -81 -67 -277 78 -277 99 0 158 56 158 150 0 42 -6
63 -24 89 -45 63 -148 81 -212 38z m141 -32 c32 -17 60 -61 60 -95 0 -55 -57
-110 -115 -110 -58 0 -115 55 -115 110 0 81 95 134 170 95z"/>
<path d="M4235 700 c-12 -19 5 -28 72 -35 37 -4 85 -14 106 -22 91 -37 89
-165 -3 -203 -23 -10 -47 -10 -105 -1 -100 15 -105 14 -105 -8 0 -20 19 -25
140 -36 53 -5 71 -3 100 13 102 56 110 203 14 263 -45 28 -206 49 -219 29z"/>
<path d="M537 603 c18 -45 13 -129 -13 -192 -21 -53 -58 -66 -87 -30 -17 21
-19 34 -13 105 3 44 9 95 12 112 l7 32 -207 0 c-187 0 -207 -2 -214 -17 -4
-10 -7 -85 -7 -168 0 -121 4 -160 19 -200 39 -107 113 -172 225 -201 83 -21
330 -15 406 9 182 59 263 183 296 458 7 53 9 104 6 113 -6 14 -34 16 -226 16
l-220 0 16 -37z"/>
<path d="M1079 638 c-1 -2 -20 -132 -43 -291 l-42 -287 190 2 190 3 41 288 41
287 -188 0 c-103 0 -188 -1 -189 -2z"/>
<path d="M1656 373 c-21 -148 -40 -278 -43 -290 l-5 -23 190 0 190 0 6 28 c6
27 76 533 76 545 0 4 -85 7 -188 7 l-189 0 -37 -267z"/>
<path d="M2159 638 c-1 -2 -20 -132 -43 -291 l-42 -287 190 2 191 3 37 260
c20 143 37 272 37 288 l1 27 -185 0 c-102 0 -185 -1 -186 -2z"/>
<path d="M2619 638 c-1 -2 -20 -132 -43 -291 l-42 -287 190 2 191 3 37 265
c20 146 37 275 38 288 l0 22 -185 0 c-102 0 -185 -1 -186 -2z"/>
<path d="M4253 351 c-67 -31 -100 -115 -72 -188 12 -34 58 -83 76 -83 22 0 15
35 -12 60 -33 31 -41 79 -21 122 16 33 71 76 83 64 6 -5 -11 -159 -24 -227 -4
-22 -1 -27 22 -31 15 -3 44 -1 64 4 131 36 151 212 31 275 -52 28 -91 29 -147
4z m149 -60 c61 -55 38 -152 -43 -180 -21 -7 -29 -7 -29 2 0 40 25 207 32 207
4 0 22 -13 40 -29z"/>
</g>
<g transform="translate(5.000000,119.000000) scale(0.100000,-0.100000)"
fill="#9fffd1" stroke="none" id="arrow">
<path d="M3316 576 c-3 -11 -6 -33 -6 -48 l0 -28 -131 0 -132 0 5 45 6 45
-189 0 c-187 0 -189 0 -194 -22 -7 -36 -65 -449 -65 -469 0 -18 11 -19 190
-19 l190 0 0 29 c0 61 0 61 144 61 l131 0 -3 -45 c-3 -30 0 -45 8 -45 20 0
790 254 790 260 0 3 -21 13 -47 22 -27 9 -193 65 -370 126 -309 105 -322 109
-327 88z"/>
<path d="M125 553 c25 -137 77 -209 250 -345 39 -31 86 -72 104 -92 l35 -36
219 0 219 0 -6 38 c-19 104 -90 195 -246 315 -52 40 -108 92 -125 114 l-30 42
-214 0 -213 1 7 -37z"/>
<path d="M1135 568 c-7 -36 -65 -449 -65 -469 0 -18 11 -19 189 -19 l190 0 3
33 3 32 119 3 118 3 -4 -36 -4 -35 188 0 c186 0 188 0 193 23 7 35 65 448 65
468 0 18 -11 19 -190 19 l-190 0 0 -24 c0 -13 -3 -38 -6 -55 l-6 -31 -119 0
-119 0 6 43 c3 23 7 48 10 55 3 9 -39 12 -186 12 -188 0 -190 0 -195 -22z"/>
<path d="M2215 568 c-7 -36 -65 -449 -65 -469 0 -18 11 -19 188 -19 l188 0 33
238 c18 130 35 245 37 255 5 16 -9 17 -185 17 -190 0 -191 0 -196 -22z"/>
</g>
</svg>

</template>

<style scoped>
</style>