import { createStore } from 'vuex'
import { auth } from "./auth.module";

export default createStore({
  state: {
    filterData: {}
  },
  mutations: {
    updateFilter(state, filterOpts) {
      var filterName = filterOpts.name;
      state.filterData[filterName] = filterOpts.data;
    }
  },
  getters: {
    getFilter: (state) => (filterName) => {
      return state.filterData[filterName];
    }
  },
  actions: {
  },
  modules: {
    auth
  }
})
