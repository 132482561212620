<template>
  <div class="container">
    <MqResponsive>
      <div id="Landing" :class="responsiveClass">
        <div id="logo">
          <ShiftZone></ShiftZone>
        </div>
        <div id="links">
          <SZBtn @click="openFbApp">Facebook</SZBtn>
          <SZBtn @click="openShopeeLink">Shopee</SZBtn>
          <SZBtn @click="openLazLink">Lazada</SZBtn>
        </div>
        <div class="note">Site under construction</div>
      </div>
    </MqResponsive>
  </div>
</template>

<script>
import ShiftZone from "@szc/app/ShiftZone.vue";
import SZBtn from "@szc/app/SZBtn.vue";
import { MqResponsive } from "vue3-mq";

export default {
  name: "Landing",
  props: {
    centerText: String,
  },
  components: {
    ShiftZone,
    MqResponsive,
    SZBtn
  },
  inject: ["mq"],
  provide() {
    return {
      dark: true
    }
  },
  // TODO composable links
  methods: {
    openLazLink() {
      window.open("https://www.lazada.com.ph/shop/shiftzonetech");
    },
    openShopeeLink() {
      window.open("https://shopee.ph/shiftzone.tech");
    },
    openFbApp() {
      this.mq.mdPlus ? window.open("https://www.facebook.com/shiftzone.tech/") : window.location = "fb://page/101199492217741/";
    }
  },
  computed: {
    responsiveClass() {
      return this.mq.mdPlus ? "desktop" : "mob";
    }
  }
};
</script>

<style scoped>
.container {
  height: 100vh;
  background-color: var(--grey);
  color: var(--white);
}

#Landing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

body .main-content {
  background-color: var(--grey);
  color: var(--white);
}

#Landing.mob {
  height: 75vh;
}

#Landing.desktop {
  height: 100vh;
}

#Landing.desktop #logo {
  width: 50vw;
}

#Landing.mob #logo {
  width: 75vw;
}

#links {
  display: flex;
  flex-direction: row;
  margin: 10px;
  gap: 10px;
}

#links a {
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--white);
  border-radius: 2px;
  box-sizing: border-box;
}

#links a:hover {
  border-bottom: 1px solid var(--mint-green);
}


#links h2,
#links h3 {
  margin: 0px;
  padding: 5px;
}

.note {
  font-size: 0.5rem;
}
</style>