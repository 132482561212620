<template>
  <div class="footer-bar">
    <span class="created-by">Shift Zone</span>
    <div class="icons">
      <div class="links">
        <img src="@/assets/svg/icons8-facebook-logo.svg" alt="Like us on Facebook" @click="openFbLink"/>
        <img src="@/assets/svg/icons8-shopee.svg" alt="Follow us on Shopee" @click="openShopeeLink"/>
        <img src="@/assets/svg/icons8-lazada.svg" alt="Follow us on Lazada" @click="openLazLink"/>
      </div>
      <span class="icons-link">Icons from icons8.com</span>
    </div>
  </div>
</template>


<script>
export default {
  name: "Footer",
  props: {},
  inject: ["mq"],
  methods: {
    openLazLink() {
      window.open("https://www.lazada.com.ph/shop/shiftzonetech");
    },
    openShopeeLink() {
      window.open("https://shopee.ph/shiftzone.tech");
    },
    openFbLink() {
      if(this.mq === 'sm' || this.mq === 'xs') {
        this.openFbApp();
      } else {
        window.open("https://facebook.com/shiftzone.tech/");
      }
    },
    openFbApp() {
      window.location = "fb://page/101199492217741/";
    },
    shiftZoneLink() {
      if(this.$router.currentRoute.value.name === 'Home') {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
        this.$router.push('/');
      }
    }
  },
};
</script>

<style scoped>
.footer-bar {
  min-height: 100px;
  background: var(--mint-green);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey);
  padding: 0px 10vw;
  width: 100%;
  box-sizing: border-box;
}

.created-by {
  font-family: "Baron";
  font-size: 1rem;
}

.icons img {
  margin: 5px;
  cursor: pointer;
}

.icons-link {
  font-size: 0.5rem;
}
</style>